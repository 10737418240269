/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter, Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import MyProperties from '../Cruds/MyProperties/index';
import Clients from '../Cruds/Clients/index';
import Searches from '../Cruds/Searches/index';
import SavedSearches from '../Cruds/SavedSearches/index';
import Offices from '../Cruds/Offices/index';
import Agents from '../Cruds/Agents/index';
import Tickets from '../Cruds/Tickets/index';
import RecentMatchedProperties from '../Cruds/RecentMatchedProperties/index';
import Notes from '../Cruds/Notes/index';
import MatchedProperties from '../Cruds/MatchedProperties/index';
import Reminders from '../Cruds/Reminders/index';
import News from '../Cruds/News/index';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Verification from '../Landing/LogIn/components/index.jsx';
import GoogleAuthenticator from '../Landing/GoogleLogin/index.jsx';
import VerifyGoogleAuthenticator from '../Landing/GoogleLogin/components/index.jsx';


const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
        <Routes>
          <Route path='/sampleform'
            element={<SampleForm />}
          ></Route>
          <Route exact
            path="/employees"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
                ? <Employees />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_employees"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_employees/:id"
            element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
                ? <Employees />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/myProperties"
            element={
              roles['My Properties'] &&
                (roles['My Properties'].type === "Edit" || roles['My Properties'].type === "View")
                ? <MyProperties />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_myProperties"
            element={
              roles['My Properties'] &&
                (roles['My Properties'].type === "Edit")
                ? <MyProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_myProperties/:id"
            element={
              roles['My Properties'] &&
                (roles['My Properties'].type === "Edit")
                ? <MyProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_myProperties/:id"
            element={
              roles['My Properties'] &&
                (roles['My Properties'].type === "Edit" || roles['My Properties'].type === "View")
                ? <MyProperties />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/clients"
            element={
              roles['Clients'] &&
                (roles['Clients'].type === "Edit" || roles['Clients'].type === "View")
                ? <Clients />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_clients"
            element={
              roles['Clients'] &&
                (roles['Clients'].type === "Edit")
                ? <Clients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_clients/:id"
            element={
              roles['Clients'] &&
                (roles['Clients'].type === "Edit")
                ? <Clients />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_clients/:id"
            element={
              roles['Clients'] &&
                (roles['Clients'].type === "Edit" || roles['Clients'].type === "View")
                ? <Clients />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/searches"
            element={
              roles['Searches'] &&
                (roles['Searches'].type === "Edit" || roles['Searches'].type === "View")
                ? <Searches />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_searches"
            element={
              roles['Searches'] &&
                (roles['Searches'].type === "Edit")
                ? <Searches />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_searches/:id"
            element={
              roles['Searches'] &&
                (roles['Searches'].type === "Edit")
                ? <Searches />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_searches/:id"
            element={
              roles['Searches'] &&
                (roles['Searches'].type === "Edit" || roles['Searches'].type === "View")
                ? <Searches />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/savedSearches"
            element={
              roles['Saved Searches'] &&
                (roles['Saved Searches'].type === "Edit" || roles['Saved Searches'].type === "View")
                ? <SavedSearches />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_savedSearches"
            element={
              roles['Saved Searches'] &&
                (roles['Saved Searches'].type === "Edit")
                ? <SavedSearches />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_savedSearches/:id"
            element={
              roles['Saved Searches'] &&
                (roles['Saved Searches'].type === "Edit")
                ? <SavedSearches />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_savedSearches/:id"
            element={
              roles['Saved Searches'] &&
                (roles['Saved Searches'].type === "Edit" || roles['Saved Searches'].type === "View")
                ? <SavedSearches />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/offices"
            element={
              roles['Offices'] &&
                (roles['Offices'].type === "Edit" || roles['Offices'].type === "View")
                ? <Offices />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_offices"
            element={
              roles['Offices'] &&
                (roles['Offices'].type === "Edit")
                ? <Offices />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_offices/:id"
            element={
              roles['Offices'] &&
                (roles['Offices'].type === "Edit")
                ? <Offices />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_offices/:id"
            element={
              roles['Offices'] &&
                (roles['Offices'].type === "Edit" || roles['Offices'].type === "View")
                ? <Offices />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/agents"
            element={
              roles['Agents'] &&
                (roles['Agents'].type === "Edit" || roles['Agents'].type === "View")
                ? <Agents />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_agents"
            element={
              roles['Agents'] &&
                (roles['Agents'].type === "Edit")
                ? <Agents />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_agents/:id"
            element={
              roles['Agents'] &&
                (roles['Agents'].type === "Edit")
                ? <Agents />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_agents/:id"
            element={
              roles['Agents'] &&
                (roles['Agents'].type === "Edit" || roles['Agents'].type === "View")
                ? <Agents />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/tickets"
            element={
              roles['Tickets'] &&
                (roles['Tickets'].type === "Edit" || roles['Tickets'].type === "View")
                ? <Tickets />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_tickets"
            element={
              roles['Tickets'] &&
                (roles['Tickets'].type === "Edit")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_tickets/:id"
            element={
              roles['Tickets'] &&
                (roles['Tickets'].type === "Edit")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_tickets/:id"
            element={
              roles['Tickets'] &&
                (roles['Tickets'].type === "Edit" || roles['Tickets'].type === "View")
                ? <Tickets />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/recentMatchedProperties"
            element={
              roles['Recent Matched Properties'] &&
                (roles['Recent Matched Properties'].type === "Edit" || roles['Recent Matched Properties'].type === "View")
                ? <RecentMatchedProperties />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_recentMatchedProperties"
            element={
              roles['Recent Matched Properties'] &&
                (roles['Recent Matched Properties'].type === "Edit")
                ? <RecentMatchedProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_recentMatchedProperties/:id"
            element={
              roles['Recent Matched Properties'] &&
                (roles['Recent Matched Properties'].type === "Edit")
                ? <RecentMatchedProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_recentMatchedProperties/:id"
            element={
              roles['Recent Matched Properties'] &&
                (roles['Recent Matched Properties'].type === "Edit" || roles['Recent Matched Properties'].type === "View")
                ? <RecentMatchedProperties />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/notes"
            element={
              roles['Notes'] &&
                (roles['Notes'].type === "Edit" || roles['Notes'].type === "View")
                ? <Notes />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_notes"
            element={
              roles['Notes'] &&
                (roles['Notes'].type === "Edit")
                ? <Notes />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_notes/:id"
            element={
              roles['Notes'] &&
                (roles['Notes'].type === "Edit")
                ? <Notes />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_notes/:id"
            element={
              roles['Notes'] &&
                (roles['Notes'].type === "Edit" || roles['Notes'].type === "View")
                ? <Notes />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/matchedProperties"
            element={
              roles['Matched Properties'] &&
                (roles['Matched Properties'].type === "Edit" || roles['Matched Properties'].type === "View")
                ? <MatchedProperties />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_matchedProperties"
            element={
              roles['Matched Properties'] &&
                (roles['Matched Properties'].type === "Edit")
                ? <MatchedProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_matchedProperties/:id"
            element={
              roles['Matched Properties'] &&
                (roles['Matched Properties'].type === "Edit")
                ? <MatchedProperties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_matchedProperties/:id"
            element={
              roles['Matched Properties'] &&
                (roles['Matched Properties'].type === "Edit" || roles['Matched Properties'].type === "View")
                ? <MatchedProperties />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/reminders"
            element={
              roles['Reminders'] &&
                (roles['Reminders'].type === "Edit" || roles['Reminders'].type === "View")
                ? <Reminders />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reminders"
            element={
              roles['Reminders'] &&
                (roles['Reminders'].type === "Edit")
                ? <Reminders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reminders/:id"
            element={
              roles['Reminders'] &&
                (roles['Reminders'].type === "Edit")
                ? <Reminders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_reminders/:id"
            element={
              roles['Reminders'] &&
                (roles['Reminders'].type === "Edit" || roles['Reminders'].type === "View")
                ? <Reminders />
                : <ErrorNotFound />
            }
          />
         
          <Route exact
            path="/news"
            element={
              roles['News'] &&
                (roles['News'].type === "Edit" || roles['News'].type === "View")
                ? <News />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_news"
            element={
              roles['News'] &&
                (roles['News'].type === "Edit")
                ? <News />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_news/:id"
            element={
              roles['News'] &&
                (roles['News'].type === "Edit")
                ? <News />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_news/:id"
            element={
              roles['News'] &&
                (roles['News'].type === "Edit" || roles['News'].type === "View")
                ? <News />
                : <ErrorNotFound />
            }
          />
          
          {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

          {/* <Route path="/settings" element={Settings} /> */}
          {/* <Route path="/adminSettings" element={AdminSettings} /> */}
          <Route
            path="/adminSettings"
            element={
              config.displaySettings
                ? <AdminSettings />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/uploads"
            element={
              roles["Upload Histories"] &&
                (roles["Upload Histories"]?.type === "Edit" || roles["Upload Histories"]?.type === "View")
                ? <Uploads />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities"
            element={
              roles["Activities"] &&
                (roles["Activities"]?.type === "Edit" || roles["Activities"]?.type === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/roles"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_roles"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/emailStatuses"
            element={
              roles["Email Statuses"] &&
                (roles["Email Statuses"]?.type === "Edit" || roles["Email Statuses"]?.type === "View")
                ? <EmailStatus />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"]?.type === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"]?.type === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
        </Routes >
        <Outlet />
      </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />

  </Routes>

);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/two_step_verification" element={<Verification />} />
        <Route path="/authenticator" element={<GoogleAuthenticator />} />
        <Route path="/verify_authenticator" element={<VerifyGoogleAuthenticator />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />} />

        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
