import moment from "moment";
import fetchMethodRequest from "./service";
import config from "./config";

export const getAmenities = async () => {
    try {
        const response = await fetchMethodRequest('GET', 'settings/getAmenities');
        if (response && response.respCode) {
            return response.amenities;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const updateCkEditorValues = (values) => {
    values = values.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
};

export const dateAndTimeFormat = (timestamp, tableItem) => {
    if (timestamp) {
        const localDate = moment.utc(timestamp).local();
        let hours = localDate.hours();
        let minutes = localDate.minutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
            period = 'PM';

            // Convert 12-hour format to 12 AM/PM format
            if (hours > 12) {
                hours -= 12;
            }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        const formattedDate = `${localDate.format(config.dateDayMonthFormat)}`
        if (tableItem?.fieldUse === "dateAndTime") {
            return <span style={tableItem.style} title={formattedTime}>
                {formattedDate + ' ' + formattedTime}
            </span>;
        } else {
            return <span style={tableItem?.style} title={formattedTime}>
                {formattedTime}
            </span>;
        }
    }
}